import React from "react";
import landingPhone from "./img/landing-phone.png";
import profile from "./img/profile.png";
import engage from "./img/engage.png";
import collections from "./img/collections.png";
import library from "./img/library.png";
import alee from "./img/alee.JPG";
import ahaak from "./img/ahaak.JPG";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import isEmail from "validator/lib/isEmail";

const mc_url =
  "https://scout.us4.list-manage.com/subscribe/post?u=ab56b23f4199bc623985985df&amp;id=13d3b4e1d6";

// use the render prop and your custom form
const CustomForm = () => {
  const [email, setEmail] = React.useState("");
  const [validationError, setValidationError] = React.useState(false);

  const validate = email => {
    if (!isEmail(email)) {
      setValidationError(true);
      return false;
    }

    setValidationError(false);
    return true;
  };

  return (
    <MailchimpSubscribe
      url={mc_url}
      render={({ subscribe, status, message }) => {
        const submit = event => {
          event.preventDefault();
          validate(email) &&
            subscribe({
              EMAIL: email
            });
        };

        const onChange = event => {
          console.log(event);

          setEmail(event.target.value);
        };

        const alreadySubscribed =
          message && message.includes("already subscribed");

        return (
          <div className="signup-container">
            <form className="signup-section" onSubmit={submit}>
              <input
                className="signup-input"
                type="email"
                placeholder="Enter your email"
                onChange={onChange}
                value={email}
              />

              <button className="signup-button" onClick={submit}>
                {status !== "sending" ? "Join waitlist" : "Loading..."}
              </button>
            </form>
            <div className="signup-message">
              {status === "error" && !alreadySubscribed && !validationError && (
                <div style={{ color: "#F62868" }}>
                  Oops! Looks like something went wrong
                </div>
              )}
              {status === "error" && alreadySubscribed && !validationError && (
                <div style={{ color: "#F62868" }}>
                  Your email is already signed up
                </div>
              )}
              {validationError && (
                <div style={{ color: "#F62868" }}>Invalid email</div>
              )}
              {status === "success" && !validationError && (
                <div style={{ color: "#000" }}>
                  Thanks! You're on the waitlist.{" "}
                  <a
                    class="typeform-share link"
                    href="https://scout-app.typeform.com/to/gBcGZo"
                    data-mode="popup"
                    data-hide-headers={true}
                    data-hide-footer={true}
                    data-submit-close-delay="2"
                    target="_blank"
                  >
                    Want priority access?
                  </a>
                </div>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

export const LandingPage = () => (
  <>
    <div className="landing-top-section">
      <div className="landing-text">
        <div className="header-text">Discover your next favorite place</div>
        <div className="body-text">
          Get recommendations from friends and save them into your personal
          library.
        </div>
        <CustomForm />
      </div>
      <img className="landing-phone" src={landingPhone} />
    </div>

    <div className="landing-header">Features</div>
    <div className="landing-features-section">
      <div className="landing-feature-container">
        <div className="landing-feature">
          <img className="landing-feature-image" src={profile} />
          <div className="landing-feature-header">Follow friends</div>
          <div className="landing-feature-body">
            Follow friends to see places they recommend or share your local
            knowledge of a city to your followers.
          </div>
        </div>
        <div className="landing-feature">
          <img className="landing-feature-image" src={engage} />
          <div className="landing-feature-header">Save interesting places</div>
          <div className="landing-feature-body">
            Mark places you discover as <i>Want to go</i> or <i>Like</i> them
            for future reference.
          </div>
        </div>
        <div className="landing-feature">
          <img className="landing-feature-image" src={collections} />
          <div className="landing-feature-header">Create collections</div>
          <div className="landing-feature-body">
            Easily share groups of places together and tell a story with
            photos/videos and rich text editing.
          </div>
        </div>
        <div className="landing-feature">
          <img className="landing-feature-image" src={library} />
          <div className="landing-feature-header">Organize your library</div>
          <div className="landing-feature-body">
            Your places should be treated like a library of files. Use extensive
            sorting and filtering controls to browse through your places.
          </div>
        </div>
      </div>
    </div>

    <div className="landing-about-section">
      <div className="landing-header" id="About">
        About us
      </div>
      <div className="landing-about-big-text">
        We love cities, and our mission is to help you genuinely experience
        them.{" "}
      </div>
      <div className="landing-about-container">
        <div className="landing-about-teammate">
          <img className="landing-about-photo" src={alee} />
          <div className="landing-about-teammate-name">Andrew Lee</div>
          <div className="landing-about-body">
            Product Designer at Palantir and fellow at IDEO. Previously at
            Acorns, Everlane, USC.
          </div>
          <div className="landing-about-body-link-container">
            <a
              className="landing-about-body-link"
              href="https://andrewl.ee"
              target="_blank"
            >
              Website
            </a>
            <span> · </span>
            <a
              className="landing-about-body-link"
              href="https://www.linkedin.com/in/alhyunsoo"
              target="_blank"
            >
              LinkedIn
            </a>
            <span> · </span>
            <a
              className="landing-about-body-link"
              href="https://www.twitter.com/alhyunsoo"
              target="_blank"
            >
              Twitter
            </a>
          </div>
        </div>
        <div className="landing-about-teammate">
          <img className="landing-about-photo" src={ahaak} />
          <div className="landing-about-teammate-name">Alex Haak</div>
          <div className="landing-about-body">
            Fellow at Recurse. Previously at Improbable, University of Warwick,
            HKUST.
          </div>
          <div className="landing-about-body-link-container">
            <a
              className="landing-about-body-link"
              href="https://github.com/haack"
              target="_blank"
            >
              Github
            </a>
            <span> · </span>
            <a
              className="landing-about-body-link"
              href="https://www.linkedin.com/in/alexhaak/"
              target="_blank"
            >
              LinkedIn
            </a>
          </div>
        </div>
      </div>
    </div>
  </>
);
