import React from "react";
import logo from "./img/scout_logo.svg";
import "./App.css";
import { LandingPage, LandingNav } from "./LandingPage";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { PrivacyPage } from "./PrivacyPage";

function App() {
  return (
    <div className="landing-container">
      <div className="landing-content">
        <div className="landing-navbar">
          <div className="landing-navbar-container" href=".">
            <img className="landing-navbar-logomark" src={logo} />
            <a className="landing-navbar-logo" href=".">
              Scout
            </a>
          </div>

          <div className="landing-navbar-link-section">
            <div className="local-container">
              <a
                className="landing-navbar-link"
                href="https://www.notion.so/getscout/Scout-Locals-c0efa164cb77451dbc6868ba1347938f"
                target="_blank"
              >
                Locals
              </a>
              <div className="new-tag">New</div>
            </div>
            <a className="landing-navbar-link" href="mailto:hello@scout.so">
              Contact
            </a>
          </div>
        </div>

        <Router>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/privacy" component={PrivacyPage} />
        </Router>

        <div className="landing-footer">
          <div className="footer-copyright">© 2019 Scout</div>
          <div className="footer-links">
            <a
              className="footer-text"
              href="mailto:hello@scout.so"
              target="_blank"
            >
              Contact
            </a>
            <a
              className="footer-text"
              href="https://twitter.com/scout_hq"
              target="_blank"
            >
              Twitter
            </a>
            <a className="footer-text" href="./privacy">
              Privacy policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
